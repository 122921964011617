import axios from 'axios'
const get = async function(url, params) {
    const token = localStorage.getItem("tk-scgp-covid")
    const res = await axios.get(url, {
      headers: {
        Authorization: token,
      },
      params: params,
    });
    return res.data;
  };
  
  const post = async function(url, data) {
    const token = localStorage.getItem("tk-scgp-covid")
    const res = await axios.post(url, data, {
      headers: {
        Authorization: token,
      },
    });
    return res.data
    
  };
  export {
    get,
    post
  }