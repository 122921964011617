import axios from 'axios'
import _ from 'lodash'
import store from '@/store'
import swAlert from '@/js/sweetAlert.js'
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const status = _.get(error, 'response.status')
    const msg = _.get(error, 'response.data.message')
    const errorSystem = _.get(error, 'response.data.errors')
    if (status === 401) {
        store.dispatch('setUserInfo', null)
        swAlert.error(msg, 'Unauthorized')
    }
    if ([400, 402, 500, 404].indexOf(status) !== -1) {
        swAlert.error(msg || JSON.stringify(errorSystem), 'Error' )
    }
    return Promise.reject(error);
})