
import Vuex from 'vuex'
const store = new Vuex.Store({
   state:{
      userInfo: null
   },
   getters: {
      getUserInfo: state => {
          if (!state.userInfo) return null
          return state.userInfo
      }
   },
   mutations: {
      userInfo (state, data) {
        state.userInfo = data
      }
   },
   actions: {
      async setUserInfo ({ commit }, userInfo) {
        return new Promise(resolve => {
          if (!userInfo) {
            localStorage.removeItem('tk-scgp-covid')
          }
          commit('userInfo', userInfo)
          resolve()
        })
      }
   }
})

export default store
