import { createApp } from 'vue'
import App from './App.vue'
import store from './store/index.js'
import router from './router'
import { getAuthen } from './authentication/index.js'
import VueFinalModal from 'vue-final-modal'
import '@/js/interceptor.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-next-select/dist/index.min.css'
getAuthen.then(() => {
    const app = createApp(App)
    app.use(store)
    app.use(router)
    app.use(VueFinalModal())
    app.mount('#app')
})

