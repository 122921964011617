<template>
 <div >
    <router-view></router-view>
  </div>
</template>

<script>
import './main.scss'
export default {
}
</script>

<style>

</style>
