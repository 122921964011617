import swal from 'sweetalert2'
export function error (msg, title, callback)  {
    swal.fire({
        title: title,
        text: msg,
        icon: 'error',
        allowOutsideClick: false,
        confirmButtonText: 'OK'
      }).then( () => {
        if (callback) callback()
      })
}
export function success (msg, title, callback)  {
  swal.fire({
      title: title,
      text: msg,
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonText: 'OK'
    }).then( () => {
      if (callback) callback()
    })
}
export function info (msg, title, callback)  {
    swal.fire({
        title: title,
        text: msg,
        icon: 'info',
        allowOutsideClick: false,
        confirmButtonText: 'OK'
      }).then( () => {
        if (callback) callback()
      })
}
export function warning (msg, title, callback)  {
    swal.fire({
        title: title,
        text: msg,
        icon: 'warning',
        allowOutsideClick: false,
        confirmButtonText: 'OK'
      }).then( () => {
        if (callback) callback()
      })
}
export function confirmSubmit (msg, title, callback)  {
    swal.fire({
        title: title,
        text: msg,
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'OK'
      }).then( res => {
          if (res.isConfirmed) {
            callback()
          }
      })
}
export function confirmInfo (msg, title, callback)  {
    swal.fire({
        title: title,
        text: msg,
        icon: 'info',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'OK'
      }).then( res => {
          if (res.isConfirmed) {
            callback()
          }
      })
}
export default {
    error,
    warning,
    info,
    confirmSubmit,
    confirmInfo,
    success
}