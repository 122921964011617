import * as request from '@/api'
import { baseUrl } from '@/config/api'
import store from '@/store'
const getMe = async() => {
    try {
        const res = await request.get(baseUrl+ '/api/Auth/GetMe')
        store.dispatch('setUserInfo', res)
        return true 
    } catch (error) {
        store.dispatch('setUserInfo', null)
        return false
    }
}
export {
    getMe
}