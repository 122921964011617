import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'
import _ from 'lodash'
const Login = () => import('@/views/Login')
const Main = () => import('@/views/FormSurvey')
const notFound = () => import('@/views/404')
const whiteList = ['/', '/login', '/404']
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/main',
        name: 'main',
        component: Main
    },
    {
        path: '/404',
        name: 'notfound',
        component: notFound
    },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const userInfo = _.get(store, 'getters.getUserInfo')
  if (to.path === '/login' && userInfo) {
     next({ name: 'main' })// safe redirect
  }
  else if (whiteList.indexOf(to.path) !== -1) next()
  else if (userInfo) { // has authentication
    if (to.name !== 'main') next({ name: 'notfound' })
    else next()
  } else { // no authentication
    if (to.name) {
      next({ name: 'login', query: { redirect: to.name } })
    } 
    else {
      next({ name: 'login' })
    } 
  }
})

 export default router