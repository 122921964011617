import store from '@/store/index.js'
import { getMe } from '@/js/getMe.js'
const getAuthen = new Promise ((resolve)=> {
    const token = localStorage.getItem('tk-scgp-covid')
    if (token) {
        getMe().then(() => {
            resolve()
        })
    } else {
        store.dispatch('setUserInfo', null)
        resolve()
    }
}) 
export {
    getAuthen
}
